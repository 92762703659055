<template>
  <section class="hero is-fullheight">
    <div class="hero-head">
      <Navbar />
    </div>

    <div class="hero-body is-align-items-flex-end">
      <div class="container">

        <div class="columns">
          <div class="column is-5 is-offset-4 has-text-centered">
            <h1 class="subtitle is-size-2" style="margin-bottom: -1.5rem;">
              Giving the gift
            </h1>
            <figure class="image is-relative" style="z-index: -1;">
              <img src="@/assets/girl.jpg">
            </figure>
            <h1 class="subtitle is-size-2" style="margin-top: -1rem;">
              of choice
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="hero is-white is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div data-aos="fade-down" class="column
          is-12-mobile
          is-5-tablet">
            <p class="subtitle has-text-grey mb-0">
              01
            </p>
            <h3 class="subtitle is-size-3">
              Curate your catalog for your recipients
            </h3>
            <div class="content">
              <ul>
                <li>Select high quality products and brand them with your logo</li>
                <li>Provide options for your recipients to choose from</li>
                <li>Only pay for what is claimed</li>
              </ul>
            </div>
          </div>
          <div data-aos="fade-left" class="column
          is-12-mobile 
          is-6-tablet is-offset-1-tablet">
            <figure class="image">
              <img src="@/assets/products.jpg">
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="hero is-white is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div data-aos="fade-left" class="column
          is-12-mobile 
          is-6-tablet is-offset-1-tablet">
            <figure class="image">
              <img src="https://placehold.co/600x400">
            </figure>
          </div>
          <div data-aos="fade-down" class="column
          is-12-mobile
          is-5-tablet">
            <p class="subtitle has-text-grey mb-0">
              02
            </p>
            <h3 class="subtitle is-size-3">
              The easiest way to send and gift
            </h3>
            <div class="content">
              <ul>
                <li>No merch left behind- we only create what your recipients redeem</li>
                <li>Recipients can pick colour, size, and more from your designs</li>
                <li>Bespoke experience allows your recipients to receive their merch right on their doorstep.</li>
                <li>Open and closed invites for every use case</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  

  <section class="hero is-white is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div data-aos="fade-down" class="column
          is-12-mobile
          is-3-tablet">
            <p class="subtitle has-text-grey mb-0">
              03
            </p>
            <h3 class="subtitle is-size-3">
              Leave fulfillment to us
            </h3>
          </div>
          <div data-aos="fade-left" class="column
          is-12-mobile 
          is-6-tablet">
            <figure class="image">
              <img src="@/assets/fulfillment.jpg">
            </figure>
          </div>
          <div data-aos="fade-down" class="column
          is-12-mobile
          is-3-tablet">
            <div class="content">
              <ul>
                <li>No more storing or keeping track of inventory</li>
                <li>Recipients privacy is protected</li>
                <li>Get delivery notifications</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="hero is-dark is-fullheight">
    <div class="hero-body">
      <div class="container">

        <div class="level is-justify-content-center mb-6">
          <h3 class="subtitle is-size-3">
            Here's to the present
          </h3>
        </div>

        <div class="columns is-multiline is-centered is-variable is-7">
          <div data-aos="fade-right" class="column is-4">
            <h4 class="subtitle">
              Reduce waste
            </h4>
            <p>We're passionate about protecting the environment. Only products that are redeemed are made and shipped</p>
          </div>

          <div data-aos="fade-right" class="column is-4">
            <h4 class="subtitle">
              Recipients pick what they want
            </h4>
            <p>Know your recipients will get products that they'll use, in their size</p>
          </div>

          <div data-aos="fade-right" class="column is-4">
            <h4 class="subtitle">
              All in one platform
            </h4>
            <p>Design, invite, pay, and track everything from our platform</p>
          </div>

          <div data-aos="fade-right" class="column is-4">
            <h4 class="subtitle">
              No stocking inventory
            </h4>
            <p>Our team takes care of all the production and fulfillment</p>
          </div>

          <div data-aos="fade-right" class="column is-4">
            <h4 class="subtitle">
              Lower marketing costs
            </h4>
            <p>No more wasting money on unwanted t shirts that get thrown out</p>
          </div>

          <div data-aos="fade-right" class="column is-4">
            <h4 class="subtitle">
              Leave lasting impressions
            </h4>
            <p>Make yourself memorable with the most unique client followups</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Footer />
</template>

<script>
import { onBeforeMount, ref } from 'vue'
import Navbar from '@/components/Navbar1.vue'
import Footer from '@/components/Footer.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  components: { Navbar, Footer },
  setup() {
    onBeforeMount(() => {
      import('aos').then(AOS => AOS.init())
    })

    return {

    }
  }
}
</script>

<style scoped>
.hero.is-dark {
  background: #22211F;
}
</style>